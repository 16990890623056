/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiTelevisionClassic } from '@mdi/js';
import { useInView } from 'react-intersection-observer';
import { BQLink } from '../../atoms/bqlink';
import AngleRight from '../../atoms/icons/angle-right';
import LinkList from '../../molecules/link-list';
import FooterNewsLetter from './footer-news-letter';
import CopyrightSection from '../../molecules/copyright-section';
import SocialShare from './social-share-icon-list/index';
import ShineButton from '../../atoms/shine-button';
import {
  BLOOMBERGQUINT_FOOTER_LINKS,
  GET_REG_UPDATES_FOOTER_LINKS,
  CONTACT,
  STRINGS,
  FOOTER_CONTACT_US,
  PATH,
} from '../../../constants';
import {
  getInTouchInteraction,
  downloadAppDl,
  paywallWidgetImpression,
  watchLiveInteraction,
  subscribeClick,
} from '../../../helpers/datalayer-utils';

import './new-footer.module.css';
import { FourStarIcon } from '../../atoms/material-icons';
import { isMobile, isSafari } from 'react-device-detect';

const NewFooter = ({ isSubscription, features, pageType, clientHost }) => {
  const isSubscriber = useSelector((state) => get(state, ['userData', 'isSubscriber'], null));
  const [isPath, setPath] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const userData = useSelector((state) => get(state, ['userData']));
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const hasBeenTriggeredRef = useRef(false);

  useEffect(() => {
    if (window.scrollY > 50) {
      if (inView && !hasBeenTriggeredRef.current) {
        paywallWidgetImpression();
        hasBeenTriggeredRef.current = true;
      }
    }
  }, [inView]);

  useEffect(() => {
    if (inView) {
      const script = document.createElement('script');
      script.src = 'https://cdn.yld.is/scripts/6b9a46b9-b1cd-47bb-b456-01ad93e2f4ca.js';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [inView]);

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName === PATH.SUBSCRIPTION_PAGE ||  pathName === PATH.CHURN_USER_PAGE) {
      setPath(true);
    }
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedLanguage(e.target.value);
    const redirectUrl =
      'https://hindi.ndtvprofit.com?utm_source=profit&utm_medium=referral&utm_campaign=language';
    if (value === 'हिंदी') {
      window.open(redirectUrl, '_blank');
    }
    setSelectedLanguage('English');
  };
  const formattedPageType =
    pageType === 'story-page' ? 'story' : pageType === 'home-page' ? 'home-page' : 'section-page';
  const handleClick = (type, language = null) => {
    const formattedPageType =
      pageType === 'story-page' ? 'story' : pageType === 'home-page' ? 'home-page' : 'section-page';
    const baseData = {
      event: type === 'logo' ? 'logo_click' : 'language_selection',
      page_template: formattedPageType,
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: null,
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com',
      ...(type === 'language' && {
        select_type: 'footer',
        language_type: language,
      }),
    };

    if (userData?.isLoggedIn) {
      baseData.user_status = userData?.isSubscriber ? 'subscriber' : 'non-subscriber';
    } else {
      baseData.user_status = 'anonymous-user';
    }

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  return (
    <>
      <footer styleName={isSubscription ? "footer subscription-footer" : "footer"} id="bq-footer">
        <div ref={ref} styleName="f-container" className="container f-container">
          <div>
            {!isSubscription && (
              <div styleName="logo-section">
                <div styleName="group-logo">
                  <div onClick={() => handleClick('logo')} styleName="group-logo">
                    <BQLink href="/" styleName="watch-btn">
                      <img src="/icons/ndtv_group_logo.webp" alt="NDTV" loading="lazy" />
                    </BQLink>
                  </div>
                  <select
                    value={selectedLanguage}
                    styleName="language-dropdown"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === 'हिंदी') {
                        handleClick('language', 'hindi');
                      } else if (e.target.value === 'English') {
                        handleClick('language', 'english');
                      }
                    }}
                    className="desktop-only"
                    style={{ width: isSafari ? '85px' : '79px' }}
                  >
                    <option value="English">English</option>
                    <option value="हिंदी">हिंदी</option>
                  </select>

                  <div styleName="language-switcher" className="mobile-only">
                    <span
                      style={{ fontWeight: 'var(--font-weight-bold' }}
                      onClick={() => handleClick('language', 'hindi')}
                    >
                      English
                    </span>
                    <span>|</span>
                    <a
                      href="https://hindi.ndtvprofit.com?utm_source=profit&utm_medium=referral&utm_campaign=language"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span styleName="text-color" onClick={() => handleClick('language', 'hindi')}>
                        हिंदी
                      </span>
                    </a>
                  </div>
                  <div onClick={() => handleClick('logo')}>
                    <div className="mobile-only">
                      <img
                        src="/icons/ndtv_img.png"
                        alt="NDTV"
                        width="375px"
                        height="230px"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div styleName="ndtv-logo">
                  {!isSubscriber && (
                    <>
                      <div
                        styleName="f-subscribe-button"
                        className="desktop-only"
                        onClick={() => subscribeClick(userData, formattedPageType, 'footer')}
                      >
                        <BQLink href={`${PATH.SUBSCRIPTION_PAGE}?src=subs_footer`}>
                          <ShineButton
                            className="shine-button"
                            size="small"
                            label={`Subscribe at ₹${features?.subscription?.subscription_btn_amount || ''}/day`}
                            rounded="full"
                          ></ShineButton>
                        </BQLink>
                      </div>
                      <div className="desktop-only" styleName="divider"></div>
                    </>
                  )}
                  <div
                    styleName="f-subscribe-button"
                    onClick={() => watchLiveInteraction(userData, pageType, 'footer')}
                  >
                    <BQLink href="/live-tv?src=livetv_ftr" styleName="watch-btn" title="Live TV">
                      <Icon path={mdiTelevisionClassic} color="#fff" />
                      <span>{STRINGS.WATCH_LIVE}</span>
                    </BQLink>
                  </div>
                </div>
              </div>
            )}

            {!isSubscriber && !isPath && (
              <div className="mobile-only container" styleName="mb-subsribe-wrap">
                <FourStarIcon size="32px" color="#CE9445" />
                <div styleName="content">
                  <div styleName="f-title">Get Unlimited Access</div>
                  <div styleName="f-sub-title">{`Subscribe at just ₹${features?.subscription?.subscription_btn_amount}/day`}</div>
                </div>
                <div
                  styleName="f-subscribe-button"
                  onClick={() => subscribeClick(userData, pageType, 'footer')}
                >
                  <BQLink href={`${PATH.SUBSCRIPTION_PAGE}?src=subs_footer`}>
                    <ShineButton
                      className="shine-button"
                      size="small"
                      label={STRINGS.SUBSCRIBE}
                      rounded="full"
                    ></ShineButton>
                  </BQLink>
                </div>
              </div>
            )}

            <div styleName={isSubscription ? "fh-support-links-desktop boder-none" : "fh-support-links-desktop"} className="desktop-only">
              <div styleName="bqprime-blue">
                <div styleName="ndtv-img">
                  <img src="/icons/get_in_touch.jpg" alt="get_in_touch" loading="lazy" />
                  <div styleName="title">
                    Get in <span>Touch</span>
                  </div>
                </div>
              </div>

              <div>
                <BQLink styleName="contact-support" href={`mailto:${CONTACT.EDITOR_ID}`}>
                  <div
                    styleName="email"
                    onClick={() => getInTouchInteraction('editorial feedback', userData, pageType)}
                  >
                    {STRINGS.EDITORIAL_FEEDBACK}
                    <br></br>
                    <div styleName="emai">
                      {CONTACT.EDITOR_ID}&nbsp;
                      <AngleRight width="8" height="8" />
                    </div>
                  </div>
                </BQLink>
              </div>

              <BQLink styleName="contact-support" href={`mailto:${CONTACT.SALES_ID}`}>
                <div
                  styleName="email"
                  onClick={() => getInTouchInteraction('sales', userData, pageType)}
                >
                  {STRINGS.SALES}
                  <br></br>
                  <div styleName="emai">
                    {CONTACT.SALES_ID}
                    &nbsp;
                    <AngleRight width="8" height="8" />
                  </div>
                </div>
              </BQLink>

              <BQLink
                styleName="contact-support"
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${CONTACT.SUPPORT_ID}`}
              >
                <div
                  styleName="email"
                  onClick={() => getInTouchInteraction('subscription queries', userData, pageType)}
                >
                  {STRINGS.SUBSCRIPTION_QUERIES}
                  <br></br>
                  <div styleName="emai">
                    {CONTACT.SUPPORT_ID}
                    &nbsp;
                    <AngleRight width="8" height="8" />
                  </div>
                </div>
              </BQLink>

              <BQLink
                styleName="contact-support"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ndtv.com/convergence/ndtv/new/complaintform.aspx"
              >
                <div
                  styleName="email"
                  onClick={() => getInTouchInteraction('contact support', userData, pageType)}
                >
                  {STRINGS.FOR_ANY_COMPLAINTS}
                  <br></br>
                  <div styleName="emai">
                    {CONTACT.CONTACT_ID}
                    &nbsp;
                    <AngleRight width="8" height="8" />
                  </div>
                </div>
              </BQLink>
            </div>

            <div styleName="fh-support-links-mobile" className="mobile-only">
              <div styleName="support-links-container" className="support-links-container">
                <h6 styleName="headline">{FOOTER_CONTACT_US.CONTACT_ID}</h6>
                {FOOTER_CONTACT_US.CONTACT_LIST.map((i, index) => {
                  return (
                    <a
                      styleName="links-section"
                      key={index}
                      href={i.link}
                      onClick={() => getInTouchInteraction(i.title, userData, pageType)}
                    >
                      <div styleName="icon-txt-wrap">
                        <img src={i.path} alt={i.title} height="35px" width="35px" loading="lazy" />
                        <div styleName="txt-wrap">
                          <span styleName="title">{i.title} </span>
                          <span styleName="email">{i.email}</span>
                        </div>
                      </div>
                      <AngleRight width="8" height="8" />
                    </a>
                  );
                })}
              </div>
            </div>
            {!isSubscription && (
              <div styleName="siteinfo-section">
                <div styleName="list-section">
                  <LinkList
                    links={BLOOMBERGQUINT_FOOTER_LINKS.links}
                    sectionHeading={BLOOMBERGQUINT_FOOTER_LINKS.header}
                    accordion={true}
                    styleName="si-section-list"
                  />
                </div>
                <div styleName="list-section">
                  <div styleName="list">
                    <h6 styleName="list-head">{GET_REG_UPDATES_FOOTER_LINKS.header}</h6>
                    <div styleName="vertical-list">
                      {GET_REG_UPDATES_FOOTER_LINKS.links.map((i, index) => (
                        <React.Fragment key={`${i.href}-${index}`}>
                          <a
                            styleName="item"
                            href={i.name === 'Podcasts' ? clientHost + i.href : i.href}
                            rel={i.rel}
                            target={i.target}
                            onClick={() => {
                              const baseData = {
                                event: 'footer_interaction',
                                cta_text: i.name || 'NA',
                                section_name: 'stay updated',
                                page_template: formattedPageType,
                                user_id: userData?.userProfile?.userId || 'NA',
                                user_status: 'NA',
                                login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
                                language_hit: 'english',
                                Platform: isMobile ? 'mobile' : 'desktop',
                                SiteName: 'https://www.ndtvprofit.com'
                              };

                              if (userData?.isLoggedIn) {
                                baseData.user_status = userData?.isSubscriber
                                  ? 'subscriber'
                                  : 'non-subscriber';
                              } else {
                                baseData.user_status = 'anonymous-user';
                              }

                              if (typeof window !== 'undefined' && window.dataLayer) {
                                window.dataLayer.push(baseData);
                              } else {
                                console.error('Error: window.dataLayer is not available');
                              }
                            }}
                          >
                            {i.name}
                          </a>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
                <div styleName="list-section">
                  <FooterNewsLetter />
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>

      <div styleName={isSubscription ? 'social subscription-social' : 'social'} >
        <div className="container" styleName="social-wrapper">
          <div styleName="social-section">
            <SocialShare sectionName="footer" />
            <div styleName="stock-exchange-msg">
              Note : All Stock Exchange data is delayed upto 3 mins
            </div>
          </div>
          <div styleName="image-wrapper">
            <img
              src="/icons/app-download-scanner.png"
              alt="scanner"
              styleName="scanner-img"
              className="desktop-only"
              loading="lazy"
            />
            <div styleName="app-wrapper">
              <h6 styleName="app-heading">DOWNLOAD THE APP</h6>
              <div styleName="stores">
                <BQLink
                  href="https://play.google.com/store/apps/details?id=com.ndtv.ndtvprofit&pcampaignid=web_share"
                  target="_blank "
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="/icons/google.png"
                    width="160px"
                    height="56px"
                    loading="lazy"
                    alt="play-store"
                    styleName="play-store"
                    onClick={() => downloadAppDl('play store', userData, pageType, 'footer')}
                  />
                </BQLink>
                <BQLink
                  href="https://apps.apple.com/in/app/ndtv-profit/id502359431"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="/icons/app_store.png"
                    width="160px"
                    height="56px"
                    loading="lazy"
                    alt="app-store"
                    styleName="app-store"
                    onClick={() => downloadAppDl('app store', userData, pageType, 'footer')}
                  />
                </BQLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CopyrightSection
        styling={{
          listItem: {
            fontWeight: '400',
            height: '20px',
          },
        }}
        isSubscription={isSubscription}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const publisherConfig = state.qt.config.publisher;
  return {
    typeFormConfig: get(publisherConfig, ['typeform'], ''),
    features: get(state, ['qt', 'config', 'features'], {}),
    pageType: get(state, ['qt', 'pageType'], ''),
    clientHost: get(
      state,
      ['qt', 'config', 'publisher', 'client_host'],
      'https://www.ndtvprofit.com',
    ),
  };
};

NewFooter.propTypes = {
  isSubscription: PropTypes.bool,
};

NewFooter.defaultProps = {
  isSubscription: false,
};

export default connect(mapStateToProps, null)(NewFooter);
